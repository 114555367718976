@import '../../../base.scss';

.week_days {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.week_day {
    width: 22px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    color: $text;
    margin: 0;
}

@media(max-width: 500px){
    .week_days {
        padding: 0;
        max-width: 80%;
    }

    .week_day {
        font-size: 4.8vw;
        line-height: 4vw;
        color: $text;
        margin: 0;
    }
}