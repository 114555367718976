@import '../../base.scss';

.booking {
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        padding: 0 20px 30px;
}

.subtitle {
    color: $title;
    font-size: 20px;
    line-height: 1.2;
    margin: 0;
}

.booking_data {
    min-width: 230px;
    color: $title;
    
}

.room {
    color: $title;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    margin: 15px 0;
}

.price {
    color: $title;
    font-size: 20px;
    line-height: 1.2;
    margin:  10px 0;

    & span {
        font-weight: 700;
    }
}

@media(max-width: 1000px){
    .booking {
        padding: 10px 10px 30px;
    }
}

@media(max-width: 900px){
    .booking {
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media(max-width: 500px){
    .booking {
        justify-content: center;
}

    .booking_data {
        box-sizing: border-box;
        width: 100%;
        padding: 0 20px;
    }
}