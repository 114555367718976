@import '../../base.scss';


@keyframes opacity {
    0%{
        opacity: 0;
    }
    
    100%{
        opacity: 1;
    }
}


.modal{
    box-sizing: content-box;
    position: relative;
    background: transparent;
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: $white;
    z-index: 20;
    animation: opacity 1s ease-in-out;
    margin: 40px auto 50px;
}

.cell{
    box-sizing: content-box;
    width: 100%;
    margin-top: 20px;
    border-radius: 16px;
    overflow: hidden;
}

.image{
    width: 100%;
    height: auto;
}

@media(max-width: 768px){
    .cell{
        margin-top: 10px;
    }
}