@import '../../base.scss';

.box {
    position: relative;
    min-width: 300px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    padding-bottom: 10px;
}

.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.numbers {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 20px;
}

@media(max-width: 500px){
    .box {
        max-width: 100%;
        width: 100%;
    }

    .numbers {
        padding: 20px 0 20px;
    }
}