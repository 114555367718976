@import '../../base.scss';

.modal{
    position: relative;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-family:  "Montserrat", "Arial ", sans-serif;
    color: $white;
    z-index: 20;
    overflow: hidden;
    border-radius: 16px;
    background-image: url(../../image/ModalForm/img_3.webp);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
}

.overlay{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: rgba($black, 0.4);
}

.title{
    @include title;
    color: $white;
    margin-bottom: 10px;
}

.text{
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    text-align: center;
    color: $white;
    margin: 0;
    opacity: .8;
}

.button{
    margin-top: 20px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #41a4b2 0%, #41a4b2 100%);
    color: $white;
    border: none;
    width: 90%;
    height: 40px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 5px;

    &:hover{
        cursor: pointer;
        transform: scale( .95 );
        transition: .5s;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
    }
}



