@import '../../base.scss';


.modal{
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-family:  "Montserrat", "Arial ", sans-serif;
    color: $white;
    z-index: 20;
    border-radius: 16px;
    background-image: url(../../image/ModalForm/img_3.webp);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    position: relative;
}

.text {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: -40px;
    font-size: 16px;
    opacity: .8;
    margin: 0;
    padding: 0 15px;
}

.overlay{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: rgba($black, 0.4);
    border-radius: 16px;
}

.box_logo{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
}

.logo{
    width: 70px;
    height: auto;
}

.close_icon{
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    transition: .5s;

    &:hover{
        cursor: pointer;
        transform: rotate(180deg);
        transition: all .5s;
    }
}

.box{
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.box_input{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 50px;
    background-color: transparent;
    border-radius: 6px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 10px;
    border: 1px solid $white;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    width: 100%;
    color: $text-box;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px; 
}

.input{
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
}

.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.box_textarea{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 100px;
    background-color: transparent;
    border: 1px solid $white;
    border-radius: 8px;
    padding: 0 25px 10px;
    box-sizing: border-box;
    margin-top: 10px;
}

.label_textarea{
    width: 100%;
    color: $text-box;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px;
    height: 80px;
    overflow: hidden;
}

.textarea{
    box-sizing: border-box;
    resize: none;
    width: 100%;
    min-height: 80px;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
    margin-top: 5px;
}

.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $text-box #2F2F37;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: $text-box;
}

.button_form{
    margin-top: 20px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #41a4b2 0%, #41a4b2 100%);
    color: $white;
    border: none;
    width: 90%;
    height: 40px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 5px;

    &:hover{
        cursor: pointer;
        transform: scale( .95 );
        transition: .5s;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
    }
}

.button_disabled{
    margin-top: 20px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #41a4b2 0%, #41a4b2 100%);
    color: $white;
    border: none;
    width: 90%;
    height: 40px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 5px;
    pointer-events: none;
    cursor: default;
}

.text_form{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin: 10px 0 0;
    width: 100%;
    text-align: left; 
    color: $text-box;
    max-width: 420px;
}

.link_form{
    text-decoration: none;
    color: $red;
    &:hover{
        opacity: 0.7;
        transition: .3s;
    }
}


@media(max-width: 400px){
    .text {
        bottom: -60px;
    }
}