@import '../../../base.scss';

.box {
    width: 100%;
    display: grid;
    grid-template-columns: 22px 20px 22px 20px 22px 20px 22px 20px 22px 20px 22px 20px 22px;
    row-gap: 20px;
    justify-content: center;
}

@media(max-width: 500px){
    .box {
        row-gap: 4.8vw;
        width: 85%;
        grid-template-columns: 8.466vw 3.466vw 7.466vw 3.466vw 8.466vw 3.466vw 8.466vw 3.466vw 8.466vw 3.466vw 8.466vw 3.466vw 8.466vw;
    }
}