@import '../../base.scss';

.icon_list {
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.icon_item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon_image {
    width: 25px;
    height: auto;
}

.icon_text {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: $title;
    opacity: .9;
    margin: 0;
}

@media(max-width: 460px){
    .icon_list {
        grid-template-columns: repeat(2, 1fr);
    }
}