@import '../../base.scss';

.main{
    @include main-pages;
}

.container{
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    @include title;
    margin: 20px 0 0;
    padding: 0 10px;
    box-sizing: border-box;
}

.list {
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    padding: 20px 10px;
    overflow: hidden;
}

.item{
    position: relative;
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    background-image: url(../../image/OurServices/img_1.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.item_two{
    position: relative;
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    background-image: url(../../image/OurServices/img_2.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.item_three{
    position: relative;
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    background-image: url(../../image/OurServices/img_3.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.item_four{
    position: relative;
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    background-image: url(../../image/OurServices/img_4.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.item_five{
    position: relative;
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    background-image: url(../../image/OurServices/img_5.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.item_six{
    position: relative;
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    background-image: url(../../image/OurServices/img_6.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.overlay {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, .7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.box_info {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.item_box {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item_title {
    color: $black;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 40px;
    text-align: left;
}

.item_text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: $black;
    margin: 0;

    & span {
        font-weight: 400;
    }
}

.item_action {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    color: $black;
    margin: 20px 0 20px;
}

.item_button {
    text-transform: uppercase;
    background: linear-gradient(180deg, #41a4b2 0%, #41a4b2 100%);
    color: $white;
    border: none;
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 8px;

    &:hover{
        cursor: pointer;
        transform: scale( .95 );
        transition: .5s;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
    }
}

.box_one {
    width: 100%;
}

.comment {
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: $black;
    margin: 10px 0 -25px;
}



@media(max-width: 1130px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 768px){
    
}

@media(max-width: 425px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
