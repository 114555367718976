@import '../../base.scss';

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 20;
    position: absolute;
    top: 0px;
    z-index: 500;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 20px 10px;
}

.contacts {
    box-sizing: content-box;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo {
    width: 100px;
    height: auto;
}

.logo_bl {
    width: 100px;
    height: auto;
}

.phone{
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    color: $white;
    text-decoration: none;
    white-space: nowrap;

    &:hover{
        opacity: .5;
        transition: .3s;
    }

    & span {
        font-weight: 400;
    }
}

.phone_page{
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    color: $title;
    text-decoration: none;
    white-space: nowrap;

    &:hover{
        opacity: .5;
        transition: .3s;
    }

    & span {
        font-weight: 700;
    }
}

.list{
    max-width: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
}

.item{
    box-sizing: content-box;
}

.link_icon{
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 28px;
    height: auto;
}

.button  {
    display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;
        margin-left: 10px;
        flex-shrink: 0;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
}

.button_page  {
    display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        flex-shrink: 0;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger_1.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;
        margin-left: 10px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
}

@media(max-width: 768px){

    .box{
        padding: 10px 10px;
    }

    .logo {
        width: 80px;
        height: auto;
    }

    .logo_bl {
        width: 80px;
        height: auto;
    }
}

@media(max-width: 500px){

    .logo {
        display: none;
    }

    .logo_bl {
        display: none;
    }

    .box{
        justify-content: flex-end;
    }

    .button_page  {
        margin-left: 0px;
    }

    .image_icon{
        width: 24px;
        height: auto;
    }

    .phone{
        font-size: 18px;
    }

    .button  {
        width: 25px;
    }
    
    .button_page  {
        width: 25px;
    }
}

@media(max-width: 400px){

    .image_icon{
        width: 20px;
        height: auto;
    }

    .phone{
        font-size: 16px;
    }

    .button  {
        width: 20px;
    }
    
    .button_page  {
        width: 20px;
    }
}
