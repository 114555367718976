@import '../../base.scss';

.popup{
    box-sizing: border-box;
    position: fixed;
    padding: 0 15px;
    width: 100%;
    height: 100vh;
    background-color: rgba($black, .8);
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
    z-index: 4000;
    overflow: auto;
}

.box_close_icon {
    position: fixed;
    bottom: 20px;
    right: 50%;
    background: $white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
}


.close_icon{
    background-image: url(../../image/ModalPortfolio/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    z-index: 60;
    &:hover{
        cursor: pointer;
        filter: drop-shadow(0 0 5px $title);
        transition: .3s;
    }
}