@import '../../base.scss';

.main{
    @include main-pages;
}

.box {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_two {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_three {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_four {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_five {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_5.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_six {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_6.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_seven {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_7.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_eight {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_8.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_nine {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_9.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_ten {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_10.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_eleven {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_11.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box_twelve {
    width: 100%;
    height: 91vh;
    background-image: url(../../image/AboutUs/img_12.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, .9) 0%, rgba(0, 0, 0, .6) 45.07%, rgba(0, 0, 0, 0.1) , rgba(0, 0, 0, 0));
    display: flex;
    justify-content: flex-start;
    background-blend-mode: multiply;
}

.box_info {
    margin-top: 50px;
    margin-left: 7%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo {
    width: 120px;
    height: auto;
    margin-bottom: 20px;
}

.text {
    color: $white;
    font-weight: 400;
    line-height: 1.3;
    font-size: 18px;
    margin: 0;
}

.text_gap {
    color: $white;
    font-weight: 400;
    line-height: 1.3;
    font-size: 18px;
    margin: 10px 0 0;
}

@media(max-width: 768px){
    .overlay {
        background:linear-gradient(180deg, rgba(0, 0, 0, .75) 41.13%, transparent 97.48%);
    }
}

@media(max-width: 500px){
    .overlay {
        justify-content: center;
    }

    .box_info {
        margin-top: 50px;
        margin-left: 0;
        max-width: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logo {
        width: 40%;
    }

    .text {
        font-size: 18px;
    }

    .text_gap {
        font-size: 18px;
    }
}