@import '../../../base.scss';

.box_data {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.button_left {
    background: $element;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-image: url(../../../image/Calendar/left.svg);
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    padding: 0;
    border: 0;
}

.button_right {
    background: $element;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-image: url(../../../image/Calendar/right.svg);
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    padding: 0;
    border: 0;
}

.box_text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: $text;
    margin: 0;
}

.text {
    font-size: 16px;
    line-height: 1.2;
    color: $text;
    margin: 0;
}