$white: #FFF;
$black: #252525;
$red:#CD2122;
$element: #41a4b2;

// фоны
$background: $white;
$background-footer: #242428;
$background-header: #1b1b1b;
$dark-grey: #292e32;
$box:  #eff3f4;


//техт
$text: #2f313c;
$text-gray: #939398;
$title: #1b3f5b;
$title-box: #e1dece;
$text-box: #cdecfe;

/*button*/
$button-background: linear-gradient(180deg, #84BC2D 0%, #b2dc6f 100%);
$button-color: $white;


@mixin main-column {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 30px 20px;

    @media(max-width: 768px){
        padding: 30px 10px;
    }

    @media(max-width: 425px){
        padding: 20px 10px;
    }
}

@mixin main-column-portfolio {
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 0 20px 50px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 0 10px 30px;
    }
}

/// main странички
@mixin main-pages {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 65px;

    @media(max-width: 768px){
        padding-top: 50px;
    }
}

@mixin container-column {
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@mixin text {
    width: 100%;
    color: $text;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    text-align: left;
    margin: 0 0 10px;
    
    @media(max-width: 768px){
        font-size: 16px;
    }
    
    @media(max-width: 425px){
        font-size: 16px;
    } 
}

@mixin title {
    font-family:'Trajan';
    font-weight: 400;
    font-size: 40px;
    line-height: 1.2;
    max-width: 1180px;
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
    color: $title;

    @media(max-width: 1080px){
        font-size: 40px;
    }

    @media(max-width: 768px){
        font-size: 30px;
    }

    @media(max-width: 500px){
        font-size: 28px;
    }
    
    @media(max-width: 425px){
        font-size: 26px;
    }
}


@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}

@mixin link {
    align-items: center;
    background-color: transparent;
    border: 1px solid $title-box;
    border-radius: 5px;
    color: $title-box;
    display: flex;
    font-size: 14px;
    text-transform: uppercase;
    height: 50px;
    justify-content: center;
    line-height: 1.2;
    text-decoration: none;
    transition: all .3s;
    width: 200px;
    transition: all .3s ease-out;

    &:hover {
        color: $background;
        background-color: $title-box;
        transition: all .3s ease-out;
    }
}