@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    overflow: hidden;
    gap: 20px;
}

.box_map {
    box-sizing: border-box;
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
}

.info {
    max-width: 42%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    @include title;
    text-align: left;
    margin-bottom: 20px;
}

.box_contacts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon_box {
    width: 25px;
    height: 25px;
    border: 1px solid rgba(206, 174, 139, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
}

.icon_contacts {
    width: 20px;
    height: auto;
}

.subtitle {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    color: #777;
    margin: 20px 0;
}

.link_phone {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    color: $title;
    text-decoration: none;
    transition: .3s;

    &:hover {
        cursor: pointer;
        transition: .3s;
        color: $element;
    }
}

.link_email {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    color: #529DCD;
    transition: .3s;

    &:hover {
        cursor: pointer;
        transition: .3s;
        color: #D6625E;
    }
}

.text {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    color: $title;
    margin: 0;
}

.box_contacts_social {
    max-width: 320px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.box_link {
    width: 100%;
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 10px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #41a4b2 0%, #41a4b2 100%);
    color: $white;
    border: none;
    width: 90%;
    height: 40px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 5px;

    &:hover{
        cursor: pointer;
        transform: scale( .95 );
        transition: .5s;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
    }
}

.box_link_min {
    display: none;
}

.link_vc {
    display: flex;
    width: 150px;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 10px;
}

.icon_vc {
    width: 100%;
    height: auto;
}

@media(max-width: 900px){
    .box{
        flex-direction: column;
    }

    .info {
        max-width: 100%;
    }

    .icon_box {
        width: 30px;
        height: 30px;
    }
    
    .icon_contacts {
        width: 15px;
    }
    
    .subtitle {
        font-size: 16px;
    }
    
    .link_phone {
        font-size: 16px;
    }
    
    .link_email {
        font-size: 16px;
    }
    
    .text {
        font-size: 16px;
    }

    .box_link {
        display: none;
    }

    .link {
        width: 100%;
    }

    .box_link_min {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}