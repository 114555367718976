@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
}

.box_map{
    max-width: 100%;
    width: 100%;
    border-radius: 15px;
    aspect-ratio: 3/2;
    overflow: hidden;
}

@media(max-width: 768px){
    .main{
        max-width: 100%;
        padding: 0;
    }

    .box_map{
        aspect-ratio: 4/3;
    }
}