@import '../../../base.scss';

.prev-month-days {
    align-items: center;
    border: 1px solid rgba(32, 37, 43, 0);
    color: #777;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    line-height: 1.2;
    justify-content: center;
    text-align: center;
    transition: .2s;
}

@media(max-width: 500px){
    .prev-month-days {
        font-size: 4vw;
    }
}