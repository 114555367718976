@import '../../../base.scss';

.button_left {
    background: $element;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-image: url(../../../image/Calendar/left.svg);
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    padding: 0;
    border: 0;
}

.button_right {
    background: $element;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-image: url(../../../image/Calendar/right.svg);
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    padding: 0;
    border: 0;
}