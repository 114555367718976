@import '../../base.scss';

.main{
    @include main-pages;
}

.container{
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    @include title;
    margin: 20px 0 0;
    padding: 0 10px;
    box-sizing: border-box;
}

.list {
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    padding: 20px 10px;
    overflow: hidden;
}

.item{
    position: relative;
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    padding-bottom: 10px;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }

    &:hover span{
        background: $element;
    }
}

.link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cell_image{
    width: 100%;
    border-radius: 8px;
}

.cell_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.cell_subtitle{
    font-family:'Trajan';
    color: $title;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
}

.cell_number {
    font-family:'Trajan';
    color: $title;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
}

.cell_arrow {
    width: 76px;
    height: 76px;
    background: #f5f5f5;
    font-size: 22px;
    position: absolute;
    bottom: -30px;
    right: -30px;
    z-index: 2;
    border-radius: 50% 0 0 0;
    line-height: 68px;
    text-decoration: none;
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
    padding: 10px;
    box-sizing: border-box;
}

.arrow {
    width: 35px;
    height: auto;
    z-index: 10;
    margin: 0 20px 20px 0;
}

///бокс с иконками
.box_icon {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.cell_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: $element;
    margin: 10px 0 0;
}

.item_icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.icon {
    width: 25px;
    height: auto;
}

.text_icon {
    font-size: 10px;
    line-height: 1.2;
    font-weight: 700;
    color: #757582;
}

@media(max-width: 1130px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 768px){
    .cell_arrow {
        background: $element;
    }
    
    }

@media(max-width: 425px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
