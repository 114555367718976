@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.2);
        opacity: .4;
    }
}

@keyframes pulse-2 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .4;
    }
}

@keyframes pulse-3 {
    0%{
        transform: scale( 1);
    }
    100%{
        transform: scale(1.05);
    }
}

.main {
    position: relative;
    box-sizing: border-box;
    max-width: 100vw;
    height: 100vh;
    width: 100%;
}

.video_box {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image {
    width: 100%;
    height: 100%;
    background-image: url(../../image/InitialPage/img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.overlay{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .3) 30.07%, rgba(0, 0, 0, .23) 48.97%, rgba(0, 0, 0, .05) 65.86%);
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.box_info {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 100px 60px;
}

.text {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: $white;
    text-align: left;
    margin: 0 0 20px;
}

.title {
    font-family:'Trajan';
    font-size: clamp(30px, calc(36px + (60 - 30) * ((100vw - 768px) / (1920 - 768))), 60px);
    font-weight: 400;
    color: $white;
    text-align: left;
    margin: 0;
}

.title_min {
    display: none;
}

/* background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .5) 30.07%, rgba(0, 0, 0, .13) 48.97%, rgba(0, 0, 0, 0) 65.86%);*/

.box_button {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    position: absolute;
    bottom: 0;
    padding-bottom: 50px;
}

.item {
    position: relative;
    cursor: pointer;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item_box {
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    animation: pulse-3 1s infinite;
}

.item_image {
    width: 30px;
    height: auto;
}

.item_text {
    max-width: 71px;
    color: $element;
    font-size: 9px;
    font-weight: 400;
    text-align: center;
    margin: 3px 0 0;
}

.pulse{
    background: $element;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-1 1s infinite;
    z-index: 1;
}

.pulse_two{
    background: $element;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-2 1s infinite;
    z-index: 2;
}

.logo {
    display: none;
}

@media(max-width: 768px){
    .overlay{
        background: linear-gradient(to bottom, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, .33) 20.07%, rgba(0, 0, 0, 0.13) 28.86%);
    }

    .box_button {
        gap: 30px;
        padding-bottom: 70px;
    }

    .item {
        width: 105px;
        height: 105px;
    }

    .item_box {
        width: 105px;
        height: 105px;
    }
}

@media(max-width: 500px){
    .box_info {
        padding: 20px 20px 200px 20px;
    }

    .logo {
        display: block;
        width: 150px;
        height: auto;
        margin-bottom: 20px;
    }
}

@media(max-width: 425px){
    .title {
        display: none;
    }
    
    .title_min {
        display: block;
        font-family:'Trajan';
        font-size: clamp(30px, calc(36px + (60 - 30) * ((100vw - 768px) / (1920 - 768))), 60px);
        font-weight: 400;
        color: $white;
        text-align: left;
        margin: 0;
    }
////////////
    .box_button {
        gap: 20px;
        padding-bottom: 70px;
    }

    .item {
        width: 95px;
        height: 95px;
    }

    .item_box {
        width: 95px;
        height: 95px;
    }
}