@import '../../base.scss';


.modal{
    box-sizing: content-box;
    position: relative;
    background: transparent;
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    z-index: 20;
    animation: opacity 1s ease-in-out;
    aspect-ratio: 3/2;
}

.close_icon{
    position: absolute;
    top: -25px;
    right: 5px;
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    transition: .5s;
    z-index: 1000;

    &:hover{
        cursor: pointer;
        transform: rotate(180deg);
        transition: all .5s;
    }
}

.video {
    cursor: pointer;
    width: 100%;
    height: 100%;
}