@import '../../../base.scss';

.day-number {
    align-items: center;
    border: 1px solid #20252b00;
    color: $black;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    height: 100%;
    justify-content: center;
    text-align: center;
    transition: .2s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.chosen{
    color: #fff;
    background: #41A4B2;
    border-radius: 50%;
}

.day-number:hover{
    border: rgba(255, 255, 255, 0);
}

.colored_left{
    background: linear-gradient(
                    90deg, #FFF 50%, rgba(65, 167, 180, 0.52) 50%);
}
.colored_right{
    background: linear-gradient(
                    90deg, rgba(65, 167, 180, 0.52) 50%, #FFF 50%);
}
.colored_full{
    background: rgba(65, 167, 180, 0.52)
}
.day-number-space{
    transition: 0.2s;
}
.right-rounded{
    border-radius: 0% 20% 20% 0%;
}
.left-rounded{
    border-radius: 20% 0% 0% 20%;
}

@media(max-width: 500px){
    .day-number {
        font-size: 4vw;
    }
}