.swiper {
    width: 100%;
    height: 100%;
}

.swiper-wrapper{
    align-items: stretch;
}

.swiper .swiper-slide {
    height: auto;
}

.swiper-slide {
    filter: blur(0);
}

.swiper-slide-active {
    filter: blur(0);
}

.swiper-pagination {
    font-size: 18px;
    font-weight: 500;
}
