@import '../../base.scss';

.list{
    max-width: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    margin-top: 5px;
}

.item{
    box-sizing: content-box;
}

.link_icon{
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 28px;
    height: auto;
}