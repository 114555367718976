@import '../../base.scss';

.main{
    @include main-pages;
    padding-top: 65px;
}

.container{
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.room {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 30px 20px;
}

.list_image {
    max-width: 50%;
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 3/2;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
    object-fit: cover;
}

.box_price {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.room_title {
    font-family:'Trajan';
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    margin: 0;
    color: $title;
}

.room_price {
    font-family:'Trajan';
    font-weight: 500;
    font-size: 28px;
    line-height: 1;
    margin: 10px 0 0;
    color: $title;
}

.room_line {
    max-width: 100%;
    width: 100%;
    height: 1px;
    background: $element;
    margin: 20px auto;
}

////

.room_info {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: $background;
    border-radius: 16px;
}

///кнопки
.box_button {
    max-width: 360px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media(max-width: 1000px){
    .room {
        padding: 10px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .room_info {
        padding: 10px 0;
    }

    .list_image {
        max-width: 100%;
    }
}