@import '../../base.scss';

.box_icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.cell_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: $element;
    margin: 10px 0 0;
}

.item_icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.icon_play {
    width: 40px;
    height: auto;
    cursor: pointer;
}

.icon {
    width: 40px;
    height: auto;
}

.text_icon {
    max-width: 80px;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 700;
    color: $element;
}

@media(max-width: 1080px){
    .box_icon {
        gap: 10px;
    }
}

@media(max-width: 460px){
    .box_icon {
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px;
        padding: 0 20px;
    }

    .item_icon {
        min-width: 141px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .icon {
        width: 12vw;
    }

    .icon_play {
        width: 12vw;
    }
}

@media(max-width: 360px){
    .box_icon {
        padding: 0;
    }

}