@import '../../base.scss';

.main{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 10px 5px;
    background-color: $white;
}

.box{
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.icon {
    width: 80px;
    height: auto;
}


@media (max-width: 768px){
    .main{
        padding: 10px;
    }
}

@media(max-width: 425px){
    .menu_link{
        font-size: 14px;
    }
    
    .menu_text{
        font-size: 14px;
    }

    .icon {
        width: 60px;
        height: auto;
    }
}
